import type {
    Address,
    ApiAddress,
    ApiConfirmationByContractor,
    ApiArticle,
    ApiArticleQuantity,
    ApiCancellation,
    ApiCargoEmpties,
    ApiCargoMaterial,
    ApiCoordinates,
    ApiDimension,
    ApiGoods,
    ApiHandlingUnit,
    ApiLoading,
    ApiLocation,
    ApiPackable,
    ApiParty,
    ApiShipment,
    ApiTimeWindow,
    ApiTransportOrder,
    ApiTransportOrderList,
    ApiUnloading,
    ApiWeight,
    Article,
    Cancellation,
    CargoEmpties,
    CargoMaterial,
    ConfirmationByContractor,
    Coordinates,
    Dimension,
    Goods,
    HandlingUnit,
    Loading,
    Location,
    Packable,
    Party,
    Shipment,
    TimeWindow,
    TransportOrder,
    TransportOrderList,
    Unloading,
    ConfirmedTransportOrder,
    ApiConfirmedTransportOrder,
    ShipmentExecutionState,
    ApiShipmentExecutionState,
    EstimatedTimeOfArrivalState,
    ApiEstimatedTimeOfArrivalState,
    TransportOrderExecutionState,
    ApiTransportOrderExecutionState,
    MeansOfTransportState,
    ApiMeansOfTransportState,
    LoadingState,
    ApiLoadingState,
    ApiTransportationStateState,
    TransportationStateState,
    TransportationState,
    ApiTransportationState,
    ApiPlanned,
    ApiInTransit,
    ApiArrivedAt,
    Planned,
    InTransit,
    ArrivedAt,
    Finished,
    ApiFinished,
    PlanningStateState,
    ApiPlanningStateState,
    PlanningState,
} from './orderExchange.types';
import {
    ApiAddressCodec,
    ApiArrivedAtCodec,
    ApiArticleCodec,
    ApiArticleQuantityCodec,
    ApiCancellationCodec,
    ApiCargoEmptiesCodec,
    ApiCargoMaterialCodec,
    ApiConfirmationByContractorCodec,
    ApiConfirmedTransportOrderCodec,
    ApiCoordinatesCodec,
    ApiDimensionCodec,
    ApiEstimatedTimeOfArrivalStateCodec,
    ApiFinishedCodec,
    ApiGoodsCodec,
    ApiHandlingUnitCodec,
    ApiInTransitCodec,
    ApiLoadingCodec,
    ApiLoadingStateCodec,
    ApiLocationCodec,
    ApiMeansOfTransportStateCodec,
    ApiPackableCodec,
    ApiPartyCodec,
    ApiPlannedCodec,
    ApiShipmentCodec,
    ApiShipmentExecutionStateCodec,
    ApiTimeWindowCodec,
    ApiTransportOrderCodec,
    ApiTransportOrderExecutionStateCodec,
    ApiTransportOrderListCodec,
    ApiTransportationStateCodec,
    ApiTransportationStateStateCodec,
    ApiUnloadingCodec,
    ApiWeightCodec,
    ApiPlanningStateStateCodec,
} from './orderExchange.codecs';
import { makeMapper } from '../makeMapper';

const getOrderStatus = (
    confirmationByContractor?: ApiConfirmationByContractor,
    shipments?: ApiShipment[]
): 'confirmed' | 'cancelled' | 'open' => {
    const allShipmentsCancelled =
        !!shipments && shipments.length > 0 && shipments.every(shipment => !!shipment.cancellation);
    if (allShipmentsCancelled) {
        return 'cancelled';
    }
    if (confirmationByContractor) {
        return 'confirmed';
    }
    return 'open';
};

export const timeWindowMapper = makeMapper<TimeWindow, ApiTimeWindow>({
    codec: ApiTimeWindowCodec,
    toDomain: ({ start_at: startAt, finish_at: finishAt }) => ({ startAt, finishAt }),
    fromDomain: ({ startAt: start_at, finishAt: finish_at }) => ({ start_at, finish_at }),
});

export const coordinatesMapper = makeMapper<Coordinates, ApiCoordinates>({ codec: ApiCoordinatesCodec });

export const addressMapper = makeMapper<Address, ApiAddress>({
    codec: ApiAddressCodec,
    toDomain: ({ street, postal_code: postalCode, city, country }) => ({ street, postalCode, city, country }),
    fromDomain: ({ street, postalCode: postal_code, city, country }) => ({ street, postal_code, city, country }),
});

export const locationMapper = makeMapper<Location, ApiLocation>({
    codec: ApiLocationCodec,
    toDomain: ({ coordinates, address }) => ({
        coordinates: coordinates && coordinatesMapper.toDomain(coordinates),
        address: address && addressMapper.toDomain(address),
    }),
    fromDomain: ({ coordinates, address }) => ({
        coordinates: coordinates && coordinatesMapper.fromDomain(coordinates),
        address: address && addressMapper.fromDomain(address),
    }),
});

export const partyMapper = makeMapper<Party, ApiParty>({
    codec: ApiPartyCodec,
    toDomain: ({ name, account_id: accountId, address }) => ({
        name,
        accountId,
        address: address && addressMapper.toDomain(address),
    }),
    fromDomain: ({ name, accountId: account_id, address }) => ({
        name,
        account_id,
        address: address && addressMapper.fromDomain(address),
    }),
});

export const loadingMapper = makeMapper<Loading, ApiLoading>({
    codec: ApiLoadingCodec,
    toDomain: ({ time_window: timeWindow, shipper, location, note, place_of_loading: placeOfLoading }) => ({
        timeWindow: timeWindowMapper.toDomain(timeWindow),
        shipper: partyMapper.toDomain(shipper),
        location: locationMapper.toDomain(location),
        note: note,
        placeOfLoading: placeOfLoading,
    }),
    fromDomain: ({ timeWindow: time_window, shipper, location, note, placeOfLoading: place_of_loading }) => ({
        time_window: timeWindowMapper.fromDomain(time_window),
        shipper: partyMapper.fromDomain(shipper),
        location: locationMapper.fromDomain(location),
        note: note,
        place_of_loading: place_of_loading,
    }),
});

export const unloadingMapper = makeMapper<Unloading, ApiUnloading>({
    codec: ApiUnloadingCodec,
    toDomain: ({ recipient, location, time_window: timeWindow, note, place_of_discharge: placeOfDischarge }) => ({
        recipient: partyMapper.toDomain(recipient),
        location: locationMapper.toDomain(location),
        timeWindow: timeWindow && timeWindowMapper.toDomain(timeWindow),
        note: note,
        placeOfDischarge: placeOfDischarge,
    }),
    fromDomain: ({ recipient, location, timeWindow: time_window, note, placeOfDischarge: place_of_discharge }) => ({
        recipient: partyMapper.fromDomain(recipient),
        location: locationMapper.fromDomain(location),
        time_window: time_window && timeWindowMapper.fromDomain(time_window),
        note: note,
        place_of_discharge: place_of_discharge,
    }),
});

export const weightMapper = makeMapper<number | undefined, ApiWeight>({
    codec: ApiWeightCodec,
    toDomain: ({ gross_weight_kg: weight }) => weight,
    fromDomain: weight => ({ gross_weight_kg: weight }),
});

export const dimensionMapper = makeMapper<Dimension, ApiDimension>({
    codec: ApiDimensionCodec,
    toDomain: ({ length_m: lengthInMeters, width_m: widthInMeters, height_m: heightInMeters }) => ({
        lengthInMeters,
        widthInMeters,
        heightInMeters,
    }),
    fromDomain: ({ lengthInMeters: length_m, widthInMeters: width_m, heightInMeters: height_m }) => ({
        length_m,
        width_m,
        height_m,
    }),
});

export const articleQuantityMapper = makeMapper<number, ApiArticleQuantity>({
    codec: ApiArticleQuantityCodec,
    toDomain: ({ type, value }) => value,
    fromDomain: value => ({ type: 'piece', value: value }),
});

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const packableMapper: any = makeMapper<Packable, ApiPackable>({
    codec: ApiPackableCodec,
    toDomain: packable =>
        packable.type === 'handling-unit'
            ? handlingUnitMapper.toDomain(packable as ApiHandlingUnit)
            : articleMapper.toDomain(packable as ApiArticle),
    fromDomain: packable =>
        packable.type === 'handling-unit'
            ? handlingUnitMapper.fromDomain(packable as HandlingUnit)
            : articleMapper.fromDomain(packable as Article),
});

export const articleMapper = makeMapper<Article, ApiArticle>({
    codec: ApiArticleCodec,
    toDomain: ({ id, quantity }) => ({ type: 'article', id, quantity: articleQuantityMapper.toDomain(quantity) }),
    fromDomain: ({ id, quantity }) => ({ type: 'article', id, quantity: articleQuantityMapper.fromDomain(quantity) }),
});

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const handlingUnitMapper: any = makeMapper<HandlingUnit, ApiHandlingUnit>({
    codec: ApiHandlingUnitCodec,
    toDomain: ({
        packaging_code: packagingCode,
        quantity,
        contents,
        dimension,
        weight,
        stacking_factor: stackingFactor,
    }) => ({
        type: 'handling-unit',
        packagingCode,
        quantity,
        contents: contents.map(packableMapper.toDomain),
        dimension: dimension && dimensionMapper.toDomain(dimension),
        weight: weight && weightMapper.toDomain(weight),
        stackingFactor,
    }),
    fromDomain: ({
        packagingCode: packaging_code,
        quantity,
        contents,
        dimension,
        weight,
        stackingFactor: stacking_factor,
    }) => ({
        type: 'handling-unit',
        packaging_code,
        quantity,
        contents: contents.map(packableMapper.fromDomain),
        dimension: dimension && dimensionMapper.fromDomain(dimension),
        weight: weight && weightMapper.fromDomain(weight),
        stacking_factor,
    }),
});

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const goodsMapper: any = makeMapper<Goods, ApiGoods>({
    codec: ApiGoodsCodec,
    toDomain: goods =>
        goods.type === 'cargo-material'
            ? cargoMaterialMapper.toDomain(goods as ApiCargoMaterial)
            : cargoEmptiesMapper.toDomain(goods as ApiCargoEmpties),
    fromDomain: goods =>
        goods.type === 'cargo-material'
            ? cargoMaterialMapper.fromDomain(goods as CargoMaterial)
            : cargoEmptiesMapper.fromDomain(goods as CargoEmpties),
});

export const cargoEmptiesMapper = makeMapper<Goods, ApiCargoEmpties>({
    codec: ApiCargoEmptiesCodec,
    toDomain: () => ({
        type: 'cargo-empties',
        handlingUnits: [],
    }),
    fromDomain: () => ({
        type: 'cargo-empties',
        handling_units: [],
    }),
});

export const cargoMaterialMapper = makeMapper<Goods, ApiCargoMaterial>({
    codec: ApiCargoMaterialCodec,
    toDomain: ({ handling_units: handlingUnits }) => ({
        type: 'cargo-material',
        handlingUnits: handlingUnits.map(handlingUnitMapper.toDomain),
    }),
    fromDomain: ({ handlingUnits: handling_units }) => ({
        type: 'cargo-material',
        handling_units: handling_units.map(handlingUnitMapper.fromDomain),
    }),
});

export const cancellationMapper = makeMapper<Cancellation, ApiCancellation>({
    codec: ApiCancellationCodec,
    toDomain: ({ status }) => ({
        status,
    }),
    fromDomain: ({ status }) => ({
        status,
    }),
});

export const shipmentMapper = makeMapper<Shipment, ApiShipment>({
    codec: ApiShipmentCodec,
    toDomain: ({
        id,
        loading,
        unloading,
        goods,
        cancellation,
        ordering_party_shipment_id: orderingPartyShipmentId,
    }) => ({
        id,
        loading: loadingMapper.toDomain(loading),
        unloading: unloadingMapper.toDomain(unloading),
        goods: goodsMapper.toDomain(goods),
        cancellation: cancellation && cancellationMapper.toDomain(cancellation),
        orderingPartyShipmentId: orderingPartyShipmentId,
    }),
    fromDomain: ({
        id,
        loading,
        unloading,
        goods,
        cancellation,
        orderingPartyShipmentId: ordering_party_shipment_id,
    }) => ({
        id,
        loading: loadingMapper.fromDomain(loading),
        unloading: unloadingMapper.fromDomain(unloading),
        goods: goodsMapper.fromDomain(goods),
        cancellation: cancellation && cancellationMapper.fromDomain(cancellation),
        ordering_party_shipment_id: ordering_party_shipment_id,
    }),
});

export const confirmationByContractorMapper = makeMapper<ConfirmationByContractor, ApiConfirmationByContractor>({
    codec: ApiConfirmationByContractorCodec,
    toDomain: ({ status }) => ({
        status,
    }),
    fromDomain: ({ status }) => ({
        status,
    }),
});

export const meansOfTransportStateMapper = makeMapper<MeansOfTransportState, ApiMeansOfTransportState>({
    codec: ApiMeansOfTransportStateCodec,
    toDomain: ({ license_plate: licensePlate, country_code: countryCode, occurred_at: occurredAt }) => ({
        licensePlate,
        countryCode,
        occurredAt,
    }),
});

export const loadingStateMapper = makeMapper<LoadingState, ApiLoadingState>({
    codec: ApiLoadingStateCodec,
    toDomain: ({ access_code: accessCode, time_window: timeWindow, occurred_at: occurredAt }) => ({
        accessCode,
        timeWindow: timeWindow && timeWindowMapper.toDomain(timeWindow),
        occurredAt,
    }),
});

export const estimatedTimeOfArrivalStateMapper = makeMapper<
    EstimatedTimeOfArrivalState,
    ApiEstimatedTimeOfArrivalState
>({
    codec: ApiEstimatedTimeOfArrivalStateCodec,
    toDomain: ({ value, occurred_at: occurredAt }) => ({
        value,
        occurredAt,
    }),
});

export const plannedMapper = makeMapper<Planned, ApiPlanned>({
    codec: ApiPlannedCodec,
    toDomain: ({ status }) => ({ status }),
});
export const inTransitMapper = makeMapper<InTransit, ApiInTransit>({
    codec: ApiInTransitCodec,
    toDomain: ({ status, from_location: fromLocation, to_location: toLocation }) => ({
        status,
        fromLocation: locationMapper.toDomain(fromLocation),
        toLocation: locationMapper.toDomain(toLocation),
    }),
});
export const arrivedAtMapper = makeMapper<ArrivedAt, ApiArrivedAt>({
    codec: ApiArrivedAtCodec,
    toDomain: ({ status, location }) => ({
        status,
        location: locationMapper.toDomain(location),
    }),
});
export const finishedMapper = makeMapper<Finished, ApiFinished>({
    codec: ApiFinishedCodec,
    toDomain: ({ status }) => ({ status }),
});

export const transporationStateMapper = makeMapper<TransportationState, ApiTransportationState>({
    codec: ApiTransportationStateCodec,
    toDomain: transporationState => {
        if (transporationState.status === 'planned') {
            return plannedMapper.toDomain(transporationState as ApiPlanned);
        }
        if (transporationState.status === 'in-transit') {
            return inTransitMapper.toDomain(transporationState as ApiInTransit);
        }
        if (transporationState.status === 'arrived-at') {
            return arrivedAtMapper.toDomain(transporationState as ApiArrivedAt);
        }
        if (transporationState.status === 'finished') {
            return finishedMapper.toDomain(transporationState as ApiFinished);
        }
    },
});

export const transporationStateStateMapper = makeMapper<TransportationStateState, ApiTransportationStateState>({
    codec: ApiTransportationStateStateCodec,
    toDomain: ({ value: state, occurred_at: occurredAt }) => ({
        state: transporationStateMapper.toDomain(state),
        occurredAt,
    }),
});

export const planningStateMapper = makeMapper<PlanningStateState, ApiPlanningStateState>({
    codec: ApiPlanningStateStateCodec,
    toDomain: ({ planning_state: planningState, occurred_at: occurredAt }) => ({
        planningState: planningState as PlanningState,
        occurredAt,
    }),
});

export const shipmentExecutionStateMapper = makeMapper<ShipmentExecutionState, ApiShipmentExecutionState>({
    codec: ApiShipmentExecutionStateCodec,
    toDomain: ({
        shipment_id: shipmentId,
        means_of_transport: meansOfTransport,
        loading,
        unloading,
        estimated_arrival_at: estimatedArrivalAt,
        position,
        transportation_state: transportationState,
        planning_state: planningState,
    }) => ({
        shipmentId,
        meansOfTransport: meansOfTransport && meansOfTransportStateMapper.toDomain(meansOfTransport),
        loading: loading && loadingStateMapper.toDomain(loading),
        unloading: unloading && loadingStateMapper.toDomain(unloading),
        estimatedArrivalAt: estimatedArrivalAt && estimatedTimeOfArrivalStateMapper.toDomain(estimatedArrivalAt),
        position: position,
        transportationState: transportationState && transporationStateStateMapper.toDomain(transportationState),
        planningState: planningState && planningStateMapper.toDomain(planningState),
    }),
    fromDomain: ({ shipmentId, estimatedArrivalAt, position }) => ({
        shipment_id: shipmentId,
        estimated_arrival_at: estimatedArrivalAt && estimatedTimeOfArrivalStateMapper.fromDomain(estimatedArrivalAt),
        position: position,
    }),
});
export const transportOrderExecutionStateMapper = makeMapper<
    TransportOrderExecutionState,
    ApiTransportOrderExecutionState
>({
    codec: ApiTransportOrderExecutionStateCodec,
    toDomain: ({ estimated_arrival_at }) => ({
        estimatedArrivalAt: estimated_arrival_at && estimatedTimeOfArrivalStateMapper.toDomain(estimated_arrival_at),
    }),
    fromDomain: ({ estimatedArrivalAt }) => ({
        estimated_arrival_at: estimatedArrivalAt && estimatedTimeOfArrivalStateMapper.fromDomain(estimatedArrivalAt),
    }),
});

export const transportOrderMapper = makeMapper<TransportOrder, ApiTransportOrder>({
    codec: ApiTransportOrderCodec,
    toDomain: ({
        id,
        ordering_party: orderingParty,
        ordering_party_transport_order_id: orderingPartyTransportOrderId,
        contractor,
        confirmation_by_contractor: confirmationByContractor,
        execution_state: executionState,
        _embedded,
        etag,
    }) => ({
        id: id,
        orderStatus: getOrderStatus(confirmationByContractor, _embedded?.shipments),
        orderingParty: partyMapper.toDomain(orderingParty),
        orderingPartyTransportOrderId: orderingPartyTransportOrderId,
        contractor: partyMapper.toDomain(contractor),
        confirmationByContractor:
            confirmationByContractor && confirmationByContractorMapper.toDomain(confirmationByContractor),
        executionState: executionState && transportOrderExecutionStateMapper.toDomain(executionState),
        shipments: (_embedded?.shipments || Array<ApiShipment>()).map(apiShipment => {
            const apiShipmentExecutionState = _embedded?.shipment_execution_states?.find(
                executionState => executionState.shipment_id === apiShipment.id
            );
            const shipmentExecutionState =
                apiShipmentExecutionState && shipmentExecutionStateMapper.toDomain(apiShipmentExecutionState);
            return {
                ...shipmentMapper.toDomain(apiShipment),
                executionState: shipmentExecutionState,
            };
        }),
        etag,
    }),
    fromDomain: ({
        id,
        orderingParty: ordering_party,
        orderingPartyTransportOrderId: ordering_party_transport_order_id,
        contractor,
        confirmationByContractor: confirmation_by_contractor,
    }) => ({
        id: id,
        ordering_party: partyMapper.fromDomain(ordering_party),
        ordering_party_transport_order_id: ordering_party_transport_order_id,
        contractor: partyMapper.fromDomain(contractor),
        confirmation_by_contractor:
            confirmation_by_contractor && confirmationByContractorMapper.fromDomain(confirmation_by_contractor),
    }),
});

export const transportOrderListMapper = makeMapper<TransportOrderList, ApiTransportOrderList>({
    codec: ApiTransportOrderListCodec,
    toDomain: ({ items }) => ({ items: items.map(transportOrderMapper.toDomain) }),
    fromDomain: ({ items }) => ({ items: items.map(transportOrderMapper.fromDomain) }),
});

export const confirmedTransportOrderMapper = makeMapper<ConfirmedTransportOrder, ApiConfirmedTransportOrder>({
    codec: ApiConfirmedTransportOrderCodec,
    toDomain: ({ id, status }) => ({
        id,
        status,
    }),
    fromDomain: ({ id, status }) => ({
        id,
        status,
    }),
});
