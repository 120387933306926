import { makeMapper } from '../makeMapper';
import type {
    ApiMeansOfTransport,
    ApiPartialShipment,
    ApiShipment,
    ApiTimeWindow,
    ApiTour,
    ApiTourContact,
    ApiTourList,
    ApiTourStop,
    MeansOfTransport,
    Shipment,
    ShipmentType,
    TimeWindow,
    Tour,
    TourContact,
    TourList,
    TourStatus,
    TourStop,
} from './tours.types';
import {
    ApiMeansOfTransportCodec,
    ApiPartialShipmentCodec,
    ApiShipmentCodec,
    ApiTimeWindowCodec,
    ApiTourCodec,
    ApiTourContactCodec,
    ApiTourListCodec,
    ApiTourStopCodec,
} from './tours.codecs';
import { locationMapper } from '../orderExchange/orderExchange.mapper';

export const timeWindowMapper = makeMapper<TimeWindow, ApiTimeWindow>({ codec: ApiTimeWindowCodec });

export const meansOfTransportMapper = makeMapper<MeansOfTransport, ApiMeansOfTransport>({
    codec: ApiMeansOfTransportCodec,
    toDomain: ({ asset_id: assetId }) => ({ assetId }),
    fromDomain: ({ assetId: asset_id }) => ({ asset_id }),
});

export const contactMapper = makeMapper<TourContact, ApiTourContact>({
    codec: ApiTourContactCodec,
    toDomain: ({ name, phone, email, has_agreed_to_receive_emails: hasAgreedToReceiveEmails, language }) => ({
        name,
        phone,
        email,
        hasAgreedToReceiveEmails,
        language,
    }),
    fromDomain: ({ name, phone, email, hasAgreedToReceiveEmails: has_agreed_to_receive_emails, language }) => ({
        name,
        phone,
        email,
        has_agreed_to_receive_emails,
        language,
    }),
});

export const shipmentMapper = makeMapper<Shipment, ApiShipment>({
    codec: ApiShipmentCodec,
    toDomain: ({ id, transport_order_id: transportOrderId, type, contact, metadata }) => ({
        id,
        transportOrderId,
        type: type as ShipmentType,
        contact: contactMapper.toDomain(contact),
        metadata,
    }),
    fromDomain: ({ id, transportOrderId: transport_order_id, type, contact, metadata }) => ({
        id,
        transport_order_id,
        type,
        contact: contact && contactMapper.fromDomain(contact),
        metadata,
    }),
});

export const tourStopMapper = makeMapper<TourStop, ApiTourStop>({
    codec: ApiTourStopCodec,
    toDomain: ({ id, planned_arrival, location, metadata, shipments }) => ({
        id,
        plannedArrival: timeWindowMapper.toDomain(planned_arrival),
        location: locationMapper.toDomain(location),
        metadata,
        shipments: shipments.map(shipmentMapper.toDomain),
    }),
    fromDomain: ({ id, plannedArrival, location, metadata, shipments }) => ({
        id,
        planned_arrival: timeWindowMapper.fromDomain(plannedArrival),
        location: locationMapper.fromDomain(location),
        metadata,
        shipments: shipments.map(shipmentMapper.fromDomain),
    }),
});

export const tourMapper = makeMapper<Tour, ApiTour>({
    codec: ApiTourCodec,
    toDomain: ({ id, account_id: accountId, means_of_transport, stops, metadata, status }) => ({
        id,
        accountId,
        meansOfTransport: means_of_transport && meansOfTransportMapper.toDomain(means_of_transport),
        stops: stops.map(tourStopMapper.toDomain),
        metadata,
        status: status as TourStatus,
    }),
    fromDomain: ({ id, accountId: account_id, meansOfTransport, stops, metadata }) => ({
        id,
        account_id,
        means_of_transport: meansOfTransport && meansOfTransportMapper.fromDomain(meansOfTransport),
        stops: stops.map(tourStopMapper.fromDomain),
        metadata,
    }),
});

export const tourListMapper = makeMapper<TourList, ApiTourList>({
    codec: ApiTourListCodec,
    toDomain: ({ items }) => ({ items: items.map(tourMapper.toDomain) }),
    fromDomain: ({ items }) => ({ items: items.map(tourMapper.fromDomain) }),
});
