import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';

import { freightRateCalculationEstimatesMapper, freightRateCalculationParametersMapper } from './freightRates.mapper';
import type { FreightRateCalculationEstimates, FreightRateCalculationParameters } from './freightRates.types';

const baseUrl = `${config.backend.FREIGHT_RATE_SERVICE}`;

export const freightRatesApi = createApi({
    reducerPath: 'freightRates',
    tagTypes: ['FreightRates'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        postFreightRateCalculationRequest: builder.mutation<
            FreightRateCalculationEstimates,
            FreightRateCalculationParameters
        >({
            query: freightRateCalculationParameters => ({
                url: '/freight-rates',
                method: 'POST',
                headers: { 'If-None-Match': '*' },
                body: freightRateCalculationParametersMapper.fromDomain(freightRateCalculationParameters),
            }),
            transformResponse: freightRateCalculationEstimatesMapper.toDomain,
        }),
    }),
});

export const { usePostFreightRateCalculationRequestMutation } = freightRatesApi;
