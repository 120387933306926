import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { partnerListMapper } from './partnerNetwork.mapper';
import type { ApiPartnerList, PartnerList } from './partnerNetwork.types';

const baseUrl = config.backend.PARTNER_NETWORK_SERVICE;

export const partnerNetworkApi = createApi({
    reducerPath: 'partnerNetwork',
    tagTypes: ['Partners'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        fetchPartners: builder.query<PartnerList, void>({
            query: () => ({
                url: '/partners',
            }),
            providesTags: result => [
                ...(result?.items ?? []).map(({ id }) => ({ type: 'Partners' as const, id })),
                { type: 'Partners', id: 'LIST' },
            ],
            transformResponse: (response: ApiPartnerList) => partnerListMapper.toDomain(response),
        }),
    }),
});

export const { useFetchPartnersQuery } = partnerNetworkApi;
