import * as t from 'io-ts';
import { ApiLocationCodec } from '../orderExchange/orderExchange.codecs';

export const ApiTourContactCodec = t.partial({
    name: t.string,
    email: t.string,
    phone: t.string,
    language: t.string,
    has_agreed_to_receive_emails: t.boolean,
});

export const ApiTimeWindowCodec = t.type({
    from: t.string,
    to: t.string,
});

export const ApiMetadataCodec = t.record(t.string, t.string);

export const ApiShipmentCodec = t.intersection([
    t.type({
        id: t.string,
        type: t.string,
    }),
    t.partial({
        transport_order_id: t.string,
        metadata: ApiMetadataCodec,
        contact: ApiTourContactCodec,
    }),
]);

export const ApiPartialShipmentCodec = t.type({
    transport_order_id: t.string,
    shipment_id: t.string,
    id: t.string,
});

export const ApiTourStopCodec = t.intersection([
    t.type({
        id: t.string,
        planned_arrival: ApiTimeWindowCodec,
        location: ApiLocationCodec,
        shipments: t.array(ApiShipmentCodec),
    }),
    t.partial({
        metadata: ApiMetadataCodec,
    }),
]);

export const ApiMeansOfTransportCodecProps = {
    asset_id: t.string,
};
export const ApiMeansOfTransportCodec = t.type(ApiMeansOfTransportCodecProps);

export const ApiTourCodec = t.intersection([
    t.type({
        id: t.string,
        account_id: t.string,
        stops: t.array(ApiTourStopCodec),
    }),
    t.partial({
        means_of_transport: ApiMeansOfTransportCodec,
        metadata: ApiMetadataCodec,
        status: t.string,
    }),
]);

export const ApiTourListCodec = t.type({
    items: t.array(ApiTourCodec),
});
