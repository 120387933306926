import * as t from 'io-ts';
import { ApiAddressCodec } from './orderExchange.codecs';

export const ApiContractOwnerCodec = t.type({
    name: t.string,
    account_id: t.string,
});

export const ApiFreightRateCalculationEstimateCodec = t.type({
    contract_id: t.string,
    contract_name: t.string,
    contract_owner: ApiContractOwnerCodec,
    freight_rate: t.number,
    toll_cost: t.number,
    diesel_floater: t.number,
    total: t.number,
});

export const ApiFreightRateCalculationEstimatesCodec = t.type({
    items: t.array(ApiFreightRateCalculationEstimateCodec),
});

export const ApiFreightCodec = t.type({
    weight: t.number,
});

export const ApiFreightRateCalculationParametersCodec = t.type({
    freight: ApiFreightCodec,
    loading_address: ApiAddressCodec,
    unloading_address: ApiAddressCodec,
});
