import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { tourListMapper, tourMapper } from './tours.mapper';
import type { Tour, TourList } from './tours.types';

const baseUrl = `${config.backend.TOURS_SERVICE}`;

export type TourQuery = {
    tour: Tour;
    isStrict: boolean;
};

export const toursApi = createApi({
    reducerPath: 'tours',
    tagTypes: ['Tours'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        putTour: builder.mutation<void, TourQuery>({
            query: ({ tour, isStrict }) => ({
                url: `/tours/${tour.id}`,
                method: 'PUT',
                body: tourMapper.fromDomain(tour),
                params: { strict: isStrict },
            }),
            invalidatesTags: [{ type: 'Tours', id: 'LIST' }],
        }),
    }),
});

export const { usePutTourMutation } = toursApi;
