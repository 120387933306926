import * as t from 'io-ts';
import type { ApiHandlingUnit, ApiPackable } from './orderExchange.types';

export const ApiTimeWindowCodec = t.type({
    start_at: t.string,
    finish_at: t.string,
});

export const ApiCoordinatesCodec = t.type({
    latitude: t.number,
    longitude: t.number,
});

export const ApiAddressCodec = t.type({
    street: t.string,
    postal_code: t.string,
    city: t.string,
    country: t.string,
});

export const ApiContactCodec = t.partial({
    name: t.string,
    email: t.string,
    phone: t.string,
});

export const ApiPartyCodec = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        account_id: t.string,
        duns: t.string,
        address: ApiAddressCodec,
        contact: ApiContactCodec,
        plant_code: t.string,
    }),
]);

// TODO I was getting a weird "Type '"PartialType"' is not assignable to type '"InterfaceType"'" error
//      when writing the codec without the intersection
export const ApiLocationCodec = t.intersection([
    t.type({}),
    t.partial({
        address: ApiAddressCodec,
        coordinates: ApiCoordinatesCodec,
    }),
]);

export const ApiLoadingCodec = t.intersection([
    t.type({
        time_window: ApiTimeWindowCodec,
        shipper: ApiPartyCodec,
        location: ApiLocationCodec,
    }),
    t.partial({
        access_code: t.string,
        note: t.string,
        place_of_loading: t.string,
    }),
]);

export const ApiUnloadingCodec = t.intersection([
    t.type({
        recipient: ApiPartyCodec,
        location: ApiLocationCodec,
    }),
    t.partial({
        time_window: ApiTimeWindowCodec,
        access_code: t.string,
        note: t.string,
        place_of_discharge: t.string,
    }),
]);

export const ApiDimensionCodec = t.intersection([
    t.type({}),
    t.partial({
        length_m: t.number,
        width_m: t.number,
        height_m: t.number,
    }),
]);

export const ApiArticleQuantityCodec = t.type({
    type: t.string,
    value: t.number,
});

export const ApiDangerousGoodsCodec = t.partial({
    undg: t.string,
    description: t.string,
    exception_regulation: t.string,
});

export const ApiArticleCodec = t.intersection([
    t.type({
        type: t.literal('article'),
        id: t.string,
        quantity: t.union([t.number, ApiArticleQuantityCodec]),
    }),
    t.partial({
        order_reference: t.string,
        net_explosive_mass_kg: t.number,
        dangerous_goods_information: ApiDangerousGoodsCodec,
        delivery_instruction_reference: t.string,
    }),
]);

export const ApiWeightCodec = t.intersection([
    t.type({}),
    t.partial({
        gross_weight_kg: t.number,
        net_weight_kg: t.number,
    }),
]);

export const ApiPackableCodec: t.Type<ApiPackable> = t.recursion('ApiPackableCodec', () =>
    t.union([ApiHandlingUnitCodec, ApiArticleCodec])
);

export const ApiHandlingUnitCodec: t.Type<ApiHandlingUnit> = t.recursion('ApiHandlingUnitCodec', () =>
    t.intersection([
        t.type({
            type: t.literal('handling-unit'),
            packaging_code: t.string,
            quantity: t.number,
            contents: t.array(ApiPackableCodec),
        }),
        t.partial({
            dimension: ApiDimensionCodec,
            weight: ApiWeightCodec,
            stacking_factor: t.number,
        }),
    ])
);

export const ApiCargoMaterialCodec = t.type({
    type: t.literal('cargo-material'),
    handling_units: t.array(ApiHandlingUnitCodec),
});

export const ApiCargoEmptiesCodec = t.type({
    type: t.literal('cargo-empties'),
    handling_units: t.array(t.unknown),
});

export const ApiGoodsCodec = t.union([ApiCargoMaterialCodec, ApiCargoEmptiesCodec]);

export const ApiCancellationCodec = t.type({
    status: t.literal('cancelled'),
});

export const ApiShipmentCodec = t.intersection([
    t.type({
        id: t.string,
        loading: ApiLoadingCodec,
        unloading: ApiUnloadingCodec,
        goods: ApiGoodsCodec,
    }),
    t.partial({
        cancellation: ApiCancellationCodec,
        ordering_party_shipment_id: t.string,
    }),
]);

export const ApiMeansOfTransportStateCodec = t.partial({
    license_plate: t.string,
    country_code: t.string,
    occurred_at: t.string,
});

export const ApiLoadingStateCodec = t.partial({
    access_code: t.string,
    time_window: ApiTimeWindowCodec,
    occurred_at: t.string,
});

export const ApiEstimatedTimeOfArrivalStateCodec = t.intersection([
    t.type({
        value: t.string,
    }),
    t.partial({
        occurred_at: t.string,
    }),
]);

export const ApiPlannedCodec = t.type({
    status: t.literal('planned'),
});

export const ApiInTransitCodec = t.type({
    status: t.literal('in-transit'),
    from_location: ApiLocationCodec,
    to_location: ApiLocationCodec,
});

export const ApiArrivedAtCodec = t.type({
    status: t.literal('arrived-at'),
    location: ApiLocationCodec,
});

export const ApiFinishedCodec = t.type({
    status: t.literal('finished'),
});

export const ApiTransportationStateCodec = t.union([
    ApiPlannedCodec,
    ApiInTransitCodec,
    ApiArrivedAtCodec,
    ApiFinishedCodec,
]);

export const ApiTransportationStateStateCodec = t.intersection([
    t.type({
        value: ApiTransportationStateCodec,
    }),
    t.partial({
        occurred_at: t.string,
    }),
]);

export const ApiPlanningStateStateCodec = t.intersection([
    t.type({
        planning_state: t.string,
    }),
    t.partial({
        occurred_at: t.string,
    }),
]);

export const ApiTransportOrderExecutionStateCodec = t.partial({
    estimated_arrival_at: ApiEstimatedTimeOfArrivalStateCodec,
});

export const ApiShipmentExecutionStateCodec = t.intersection([
    t.type({
        shipment_id: t.string,
    }),
    t.partial({
        means_of_transport: ApiMeansOfTransportStateCodec,
        loading: ApiLoadingStateCodec,
        unloading: ApiLoadingStateCodec,
        estimated_arrival_at: ApiEstimatedTimeOfArrivalStateCodec,
        position: ApiCoordinatesCodec,
        transportation_state: ApiTransportationStateStateCodec,
        planning_state: ApiPlanningStateStateCodec,
    }),
]);

export const ApiEmbeddingCodec = t.intersection([
    t.type({}),
    t.partial({
        shipments: t.array(ApiShipmentCodec),
        shipment_execution_states: t.array(ApiShipmentExecutionStateCodec),
    }),
]);

export const ApiConfirmationByContractorCodec = t.type({
    status: t.literal('confirmed'),
});

export const ApiTransportOrderCodec = t.intersection([
    t.type({
        id: t.string,
        contractor: ApiPartyCodec,
        ordering_party: ApiPartyCodec,
    }),
    t.partial({
        ordering_party_transport_order_id: t.string,
        confirmation_by_contractor: ApiConfirmationByContractorCodec,
        execution_state: ApiTransportOrderExecutionStateCodec,
        process_indicator: t.string,
        priority: t.string,
        proposed_vehicle_type: t.string,
        etag: t.string,
        created_at: t.string,
        modified_at: t.string,
        _embedded: ApiEmbeddingCodec,
    }),
]);

export const ApiTransportOrderListCodec = t.type({
    items: t.array(ApiTransportOrderCodec),
});

export const ApiConfirmedTransportOrderCodec = t.type({
    id: t.string,
    status: t.literal('confirmed'),
});
