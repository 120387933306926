import * as t from 'io-ts';

export const AccountProfileCodec = t.type({
    id: t.string,
    display_name: t.string,
});

export const PartnerCodec = t.intersection([
    t.type({
        id: t.string,
        status: t.string,
    }),
    t.partial({
        name: t.string,
        account_profile: AccountProfileCodec,
    }),
]);

export const PartnerListCodec = t.type({
    items: t.array(PartnerCodec),
});
