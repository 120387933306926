import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { ErrorBoundary } from '../../components/ErrorBoundary';

const serviceInfoTitle = 'TransportOrders';
const serviceInfoVersion = 'v0.1.0';

const ServiceInfo = () => {
    const title = (
        <div>
            <span>{serviceInfoTitle}</span>
            <span className={'text-color-gray margin-left-10'}>{serviceInfoVersion}</span>
        </div>
    );

    return (
        <ActionBarItem id='serviceInfo'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-info-sign' />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={title}>
                <div>Service Info</div>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

const ServiceInfoWithErrorBoundary = () => {
    return (
        <ErrorBoundary>
            <ServiceInfo />
        </ErrorBoundary>
    );
};

export default ServiceInfoWithErrorBoundary;
