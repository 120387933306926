import type {
    AccountProfile,
    ApiAccountProfile,
    ApiPartner,
    ApiPartnerList,
    Partner,
    PartnerList,
} from './partnerNetwork.types';
import { makeMapper } from '../makeMapper';
import { AccountProfileCodec, PartnerCodec, PartnerListCodec } from './partnerNetwork.codecs';

export const accountProfileMapper = makeMapper<AccountProfile, ApiAccountProfile>({
    codec: AccountProfileCodec,
    toDomain: ({ id, display_name: displayName }) => ({ id, displayName }),
});

export const partnerMapper = makeMapper<Partner, ApiPartner>({
    codec: PartnerCodec,
    toDomain: ({ id, name, account_profile: accountProfile }) => {
        return {
            id,
            name: name || '',
            accountProfile: accountProfileMapper.toDomain(accountProfile),
        };
    },
});

const isConnectedAndHasAccountProfile = (apiPartner: ApiPartner) =>
    apiPartner.status.toLowerCase() === 'connected' && apiPartner.account_profile;

export const partnerListMapper = makeMapper<PartnerList, ApiPartnerList>({
    codec: PartnerListCodec,
    toDomain: ({ items }) => ({ items: items.filter(isConnectedAndHasAccountProfile).map(partnerMapper.toDomain) }),
});
