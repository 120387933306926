import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config';
import type { HereAddress } from './hereAddress.types';

export type AddressSuggestion = HereAddress;

export type AddressSuggestionList = {
    items: AddressSuggestion[];
};

const CENTER_GERMANY = [51.163409, 10.447718];
const ACCEPTED_TYPES = ['place', 'street', 'houseNumber'];

export const hereAutoSuggestApi = createApi({
    reducerPath: 'hereAutoSuggestApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.HERE_AUTO_SUGGEST_SERVICE,
    }),
    endpoints: builder => ({
        fetchAddressSuggestions: builder.query<AddressSuggestion[], { apiKey?: string; query: string }>({
            query: ({ apiKey, query }) => ({
                url: `?apiKey=${apiKey}&q=${query}&at=${CENTER_GERMANY.join(',')}`,
                responseHandler: async (response: Response) => {
                    const data: AddressSuggestionList = await response.json();
                    return data.items ? data.items.filter(it => ACCEPTED_TYPES.includes(it.resultType)) : [];
                },
            }),
        }),
    }),
});

export const { useFetchAddressSuggestionsQuery } = hereAutoSuggestApi;
