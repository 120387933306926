import { makeMapper } from '../makeMapper';
import {
    ApiContractOwnerCodec,
    ApiFreightCodec,
    ApiFreightRateCalculationEstimateCodec,
    ApiFreightRateCalculationEstimatesCodec,
    ApiFreightRateCalculationParametersCodec,
} from './freightRates.codecs';
import type {
    ApiContractOwner,
    ApiFreight,
    ApiFreightRateCalculationEstimate,
    ApiFreightRateCalculationEstimates,
    ApiFreightRateCalculationParameters,
    ContractOwner,
    Freight,
    FreightRateCalculationEstimate,
    FreightRateCalculationEstimates,
    FreightRateCalculationParameters,
} from './freightRates.types';
import { addressMapper } from './orderExchange.mapper';

export const contractOwnerMapper = makeMapper<ContractOwner, ApiContractOwner>({
    codec: ApiContractOwnerCodec,
    toDomain: ({ name, account_id: accountId }) => ({
        name,
        accountId,
    }),
    fromDomain: ({ name, accountId: account_id }) => ({
        name,
        account_id,
    }),
});

export const freightRateCalculationEstimateMapper = makeMapper<
    FreightRateCalculationEstimate,
    ApiFreightRateCalculationEstimate
>({
    codec: ApiFreightRateCalculationEstimateCodec,
    toDomain: ({
        contract_id: contractId,
        contract_name: contractName,
        contract_owner: contractOwner,
        freight_rate: freightRate,
        toll_cost: tollCost,
        diesel_floater: dieselFloater,
        total,
    }) => ({
        contractId,
        contractName,
        contractOwner: contractOwnerMapper.toDomain(contractOwner),
        freightRate,
        tollCost,
        dieselFloater,
        total,
    }),
    fromDomain: ({
        contractId: contract_id,
        contractName: contract_name,
        contractOwner: contract_owner,
        freightRate: freight_rate,
        tollCost: toll_cost,
        dieselFloater: diesel_floater,
        total,
    }) => ({
        contract_id,
        contract_name,
        contract_owner: contractOwnerMapper.fromDomain(contract_owner),
        freight_rate,
        toll_cost,
        diesel_floater,
        total,
    }),
});

export const freightRateCalculationEstimatesMapper = makeMapper<
    FreightRateCalculationEstimates,
    ApiFreightRateCalculationEstimates
>({
    codec: ApiFreightRateCalculationEstimatesCodec,
    toDomain: ({ items }) => ({ items: items.map(freightRateCalculationEstimateMapper.toDomain) }),
    fromDomain: ({ items }) => ({ items: items.map(freightRateCalculationEstimateMapper.fromDomain) }),
});

export const freightMapper = makeMapper<Freight, ApiFreight>({
    codec: ApiFreightCodec,
    toDomain: ({ weight }) => ({
        weight,
    }),
    fromDomain: ({ weight }) => ({
        weight,
    }),
});

export const freightRateCalculationParametersMapper = makeMapper<
    FreightRateCalculationParameters,
    ApiFreightRateCalculationParameters
>({
    codec: ApiFreightRateCalculationParametersCodec,
    toDomain: ({ freight, loading_address: loadingAddress, unloading_address: unloadingAddress }) => ({
        freight: freightMapper.toDomain(freight),
        loadingAddress: addressMapper.toDomain(loadingAddress),
        unloadingAddress: addressMapper.toDomain(unloadingAddress),
    }),
    fromDomain: ({ freight, loadingAddress: loading_address, unloadingAddress: unloading_address }) => ({
        freight: freightMapper.fromDomain(freight),
        loading_address: addressMapper.fromDomain(loading_address),
        unloading_address: addressMapper.fromDomain(unloading_address),
    }),
});
