import type * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { isRight } from 'fp-ts/Either';

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
const identity = (x: any) => x;

type MakeMapperOptions<DomainType, ApiType> = {
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type
    codec: t.TypeC<any> | t.IntersectionC<any> | t.RecordC<any, any> | t.Type<any, any, any>;
    toDomain?: (value: ApiType) => DomainType;
    fromDomain?: (value: DomainType) => ApiType;
};

type MakeMapperOptionsAllRequired<DomainType, ApiType> = {
    [Property in keyof MakeMapperOptions<DomainType, ApiType>]-?: MakeMapperOptions<DomainType, ApiType>[Property];
};
// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
const defaults: Omit<MakeMapperOptionsAllRequired<any, any>, 'codec'> = {
    toDomain: identity,
    fromDomain: identity,
};

export const makeMapper = <DomainType, ApiType>(options: MakeMapperOptions<DomainType, ApiType>) => {
    const { codec, fromDomain, toDomain } = { ...defaults, ...options };
    return {
        fromDomain: fromDomain ?? identity,
        toDomain: (value: unknown) => {
            const validation = codec.decode(value);
            if (isRight(validation)) {
                return toDomain(validation.right);
            }
            const errors = PathReporter.report(validation); // JSON.stringify(validation.left, null, '  ');
            throw new Error(`Failed to map value. Validation errors:\n${errors}`);
        },
    };
};
